<template>
  <div class="mx-auto w-full sm:w-1/2">
    <div class="px-4 font-merri text-2xl sm:mb-6 sm:px-8 sm:text-3xl">
      Lets get your details...
    </div>
    <div>
      <form @submit.prevent="createAccount">
        <div class="flex items-center">
          <div>
            <div class="rounded-lg p-4 sm:p-8 sm:pt-0">
              <div class="">
                <div class="pb-0 sm:py-8">
                  <div class="input-group">
                    <label>Your name</label>
                    <div class="relative flex items-center">
                      <input
                        required
                        v-model="userData.name"
                        type="text"
                        class="fs-exclude"
                        placeholder="John Smith"
                      />
                      <transition
                        enter-active-class="bounceIn"
                        leave-active-class="miniFadeOutRight"
                        mode="out-in"
                      >
                        <div
                          v-if="userData.name.length > 2"
                          class="absolute right-0 p-4 text-xs text-gray-500"
                        >
                          <font-awesome-icon
                            class="mt-1 text-lg text-teal-500"
                            :icon="['far', 'check']"
                          />
                        </div>
                      </transition>
                    </div>
                  </div>
                  <div
                    v-if="hasAccount"
                    class="warning-msg mb-4 rounded border-2 border-yellow-400 bg-yellow-100 p-4"
                  >
                    An account already exists for this email. Would you like to
                    <router-link
                      :to="{ name: 'login', params: { email: userData.email } }"
                      class="underline"
                      >Log in</router-link
                    >?
                  </div>
                  <div class="input-group">
                    <label>Email address</label>
                    <div class="relative flex items-center">
                      <input
                        required
                        v-model.trim="userData.email"
                        type="text"
                        placeholder="me@example.com"
                        class="fs-exclude"
                        :class="{ 'pr-8': checkEmail(userData.email) }"
                      />
                      <transition
                        enter-active-class="bounceIn"
                        leave-active-class="miniFadeOutRight"
                        mode="out-in"
                      >
                        <div
                          v-if="checkEmail(userData.email)"
                          class="absolute right-0 p-4 text-xs text-gray-500"
                        >
                          <font-awesome-icon
                            class="mt-1 text-lg text-teal-500"
                            :icon="['far', 'check']"
                          />
                        </div>
                      </transition>
                    </div>
                  </div>
                  <div class="input-group">
                    <label class="items-end justify-between"
                      >Create a password
                      <span class="text-xs italic text-gray-500"
                        >minimum of 5 characters</span
                      ></label
                    >

                    <password-input
                      v-model="userData.password"
                      :canView="true"
                      :showValid="true"
                    />
                  </div>
                  <div class="text-xs text-gray-500">
                    By creating an account you agree to our
                    <router-link
                      class="underline"
                      :to="{ name: 'terms conditions' }"
                      >Terms &amp; conditions</router-link
                    >
                  </div>
                </div>
              </div>

              <loading-button
                :is-loading="isLoading"
                :is-enabled="formValid"
                class="mt-8 inline-block"
              >
                <div>
                  Add delivery details
                  <font-awesome-icon
                    :icon="['far', 'arrow-right-long']"
                    class="ml-2"
                  />
                </div>
              </loading-button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import LoadingButton from '@/components/LoadingButton';
import PasswordInput from '@/components/auth/PasswordInput';
import mixpanel from 'mixpanel-browser';
import http from '@/http';
import utils from '@/utils';
import { mapGetters } from 'vuex';
import { last } from 'lodash/fp';
export default {
  metaInfo: {
    title: 'Create account'
  },
  data() {
    return {
      userData: {
        name: '',
        email: sessionStorage.getItem('email')
          ? sessionStorage.getItem('email')
          : '',
        password: '',
        referral: localStorage.getItem('referral')
          ? localStorage.getItem('referral')
          : '',
        context: {
          fbc: utils.getCookie('_fbc'),
          fbp: utils.getCookie('_fbp')
        }
      },
      marketing: JSON.parse(sessionStorage.getItem('marketing')) || false,
      isLoading: false,
      isEnabled: true,
      hasAccount: false
    };
  },
  computed: {
    formValid() {
      return this.passwordValid && this.nameValid && this.emailValid;
    },
    passwordValid() {
      return this.userData.password && this.userData.password.length >= 5
        ? true
        : false;
    },
    nameValid() {
      return this.userData.name && this.userData.name.length > 2 ? true : false;
    },
    emailValid() {
      return this.userData.email && this.checkEmail(this.userData.email)
        ? true
        : false;
    },
    ...mapGetters({
      isTaster: 'getIsTaster'
    })
  },
  components: {
    LoadingButton,
    PasswordInput
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    createAccount: function () {
      this.isLoading = true;
      this.$store.dispatch('sendMpEvent', {
        event: 'Create Account',
        properties: {
          location: this.$route.name,
          type: 'EVT'
        }
      });

      this.$store
        .dispatch('register', this.userData)
        .then(() => {
          this.$store.dispatch('getUser', true).then((user) => {
            mixpanel.identify(user.id);
            mixpanel.people.set({ $name: user.name });

            this.$store.dispatch('sendMpEvent', {
              event: 'Account created',
              properties: {
                location: this.$route.name,
                adid: JSON.stringify(utils.getStorage('adid', 'local')),
                adidLatest: last(utils.getStorage('adid', 'local')),
                type: 'EVT'
              }
            });
          });

          http
            .put('/me/options', { marketing: this.marketing })
            .then(() => {})
            .catch((error) => {
              throw Error(error.response.data.error.message);
            });

          localStorage.removeItem('referral');
          this.$store.dispatch('sendFbEvent', {
            type: 'track',
            name: 'CompleteRegistration',
            data: {}
          });
          this.$router.push({ name: 'delivery' });
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.data.error.message === 'Account exists') {
            this.hasAccount = true;
          }
          throw Error(error.response.data.error.message);
        });
    },
    checkEmail: function (email) {
      if (email) {
        let regex = new RegExp(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
        return email.match(regex);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.warning-msg {
  max-width: 360px;
  width: 100%;
}
</style>
